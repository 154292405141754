




















































































































































































































































































































































































































































































































import { Chain, ChainConfig, ChainConfigUpdate, InvestorPlatformClient, TradeSizes, User, UserUpdate } from '@/client'
import { USER_DATA_KEY } from '@/constants'
import { useStore } from '@/store'
import { Vue, Component } from 'vue-property-decorator'
import { ethers } from 'ethers'

@Component({})
export default class Console extends Vue {
  isLoading = true
  settingsDialog: boolean = false
  settingsTab: number = 0
  isMenuOpen: boolean = this.$vuetify.breakpoint.smAndUp

  isTradingEnabled = false
  isManual = false
  tradeSizesEnabled: TradeSizes[] = []
  isHighGasEnabled = false
  chainConfigs: ChainConfig[] = []
  chainConfigUpdates: (ChainConfigUpdate & {
    gasLimitEnabled: boolean,
    gasLimitGwei: string | null,
    smallTradeAmountEther: string,
    mediumTradeAmountEther: string,
    largeTradeAmountEther: string
  })[] = []
  chains: Chain[] = []
  enabledChainIds: number[] = []
  isSaveSettingsLoading = false
  showSaved = false

  get traderLink() {
    if (!this.store.currentUser || this.store.currentUser.is_investor) {
      return null
    }

    return `${window.location.origin}/trader/${this.store.currentUser.id}`
  }

  get currentEmail() {
    return this.store.currentUser?.email
  }

  get chainsLookup() {
    const lookup: Record<number, Chain> = {}
    for (const chain of this.chains) {
      lookup[chain.chainId] = chain
    }

    return lookup
  }

  get chainConfigsWithUpdates() {
    const result = []
    for (let i = 0; i < this.chainConfigs.length; i++) {
      result.push([this.chainConfigs[i], this.chainConfigUpdates[i]])
    }
    return result
  }

  get store() {
    return useStore()
  }

  async mounted() {
    await this.refresh()
    this.isLoading = false
  }

  async refresh() {
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
    this.store.currentUser = await client.getCurrentUser()
    this.chains = await client.getChains()
    const chainIds = this.chains.map(c => c.chainId)

    this.isTradingEnabled = this.store.currentUser.is_trading_enabled
    this.tradeSizesEnabled = this.getTradeSizesArray(this.store.currentUser.trade_sizes_enabled)
    this.chainConfigs = this.store.currentUser.chain_configs!
    this.chainConfigUpdates = this.chainConfigs.map(c => ({
      chainId: c.chain_id,
      isEnabled: c.is_enabled,
      isManual: c.is_manual,
      gasLimit: c.gas_limit,
      gasLimitEnabled: c.gas_limit != null,
      gasLimitGwei: c.gas_limit != null ? ethers.utils.formatUnits(c.gas_limit, 9) : null,
      smallTradeAmount: c.small_trade_amount,
      mediumTradeAmount: c.medium_trade_amount,
      largeTradeAmount: c.large_trade_amount,
      smallTradeAmountEther: ethers.utils.formatEther(c.small_trade_amount),
      mediumTradeAmountEther: ethers.utils.formatEther(c.medium_trade_amount),
      largeTradeAmountEther: ethers.utils.formatEther(c.large_trade_amount)
    }))
    this.chainConfigs = this.chainConfigs.filter(c => chainIds.includes(c.chain_id))
    this.chainConfigUpdates = this.chainConfigUpdates.filter(c => chainIds.includes(c.chainId))

    this.enabledChainIds = []
    for (const chainConfigUpdate of this.chainConfigUpdates) {
      if (chainConfigUpdate.isEnabled) {
        this.enabledChainIds.push(chainConfigUpdate.chainId)
      }
    }

    this.isManual = this.chainConfigs.every(c => c.is_manual)
  }

  async saveSettings() {
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
    this.isSaveSettingsLoading = true
    
    try {
      for (const chainConfigUpdate of this.chainConfigUpdates) {
        chainConfigUpdate.isEnabled = this.enabledChainIds.includes(chainConfigUpdate.chainId)
        chainConfigUpdate.isManual = this.isManual
        if (chainConfigUpdate.gasLimitGwei) {
          chainConfigUpdate.gasLimit = ethers.utils.parseUnits(chainConfigUpdate.gasLimitGwei, 9).toString()
        }
        if (!chainConfigUpdate.gasLimitEnabled) {
          chainConfigUpdate.gasLimit = null
        }
        if (chainConfigUpdate.gasLimitEnabled && !chainConfigUpdate.gasLimitGwei) {
          chainConfigUpdate.gasLimit = null
        }

        chainConfigUpdate.smallTradeAmount = ethers.utils.parseEther(chainConfigUpdate.smallTradeAmountEther).toString()
        chainConfigUpdate.mediumTradeAmount = ethers.utils.parseEther(chainConfigUpdate.mediumTradeAmountEther).toString()
        chainConfigUpdate.largeTradeAmount = ethers.utils.parseEther(chainConfigUpdate.largeTradeAmountEther).toString()
      }
      const userUpdate: UserUpdate = {
        isTradingEnabled: this.isTradingEnabled,
        tradeSizesEnabled: this.getTradeSizesNumber(this.tradeSizesEnabled),
        chainConfigs: this.chainConfigUpdates
      }

      await client.updateUser(userUpdate)
      await this.refresh()

      this.showSaved = true
      setTimeout(() => this.showSaved = false, 2000)
    } finally {
      this.isSaveSettingsLoading = false
    }
  }
  
  getTradeSizesArray(tradeSizes: TradeSizes) {
    const tradeSizesArray: TradeSizes[] = []
    for (const value of Object.values(TradeSizes)) {
      if (isNaN(value as any) || value == 0) {
        continue
      }

      if (tradeSizes & value as any) {
        tradeSizesArray.push(value as any)
      }
    }
    return tradeSizesArray
  }

  getTradeSizesNumber(tradeSizesArray: TradeSizes[]) {
    let tradeSizesNumber = TradeSizes.None
    for (const tradeSize of tradeSizesArray) {
      tradeSizesNumber |= tradeSize
    }
    return tradeSizesNumber
  }

  logout() {
    localStorage.removeItem(USER_DATA_KEY)
    this.$router.push('/login')
  }
}
